import React from "react";

const Footer = () => {
  return (
    <div id="socials" className="footer_container">
      <div className="container footer">
        <div className="logo">
          <a className="page-scroll" href="#page-top">
            <img
              src="./img/logo.png"
              alt="Logo of Nocha"
              className="logo-img"
            />
            <span>Nocha the 𐤊at</span>
          </a>
        </div>
        <div className="socials">
          <ul class="socials-list list no-wrap">
            <li class="item">
              <a
                href="https://x.com/NochaWyborski"
                target="_blank"
                rel="noreferrer noopener"
                class="link"
              >
                <svg
                  class="icon"
                  width="28"
                  height="26"
                  viewBox="0 0 28 26"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.0516 0H26.3452L16.9651 10.7208L28 25.3094H19.3598L12.5924 16.4615L4.84902 25.3094H0.552913L10.5858 13.8423L0 0H8.85959L14.9767 8.08733L22.0516 0ZM20.5448 22.7395H22.9238L7.56687 2.4349H5.01386L20.5448 22.7395Z"></path>
                </svg>
              </a>
            </li>
            <li class="item">
              <a
                href="https://t.me/+XTxIfkTDpI9hN2I0"
                target="_blank"
                rel="noreferrer noopener"
                class="link"
              >
                <svg
                  class="icon"
                  width="28"
                  height="24"
                  viewBox="0 0 28 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M26.1141 0.126695C25.8223 0.234037 7.64868 7.1911 2.5391 9.15146C1.75698 9.45152 0.968674 9.77574 0.787288 9.87191C-0.196862 10.3939 -0.264991 11.0629 0.620415 11.5101C0.710779 11.5558 2.26625 12.0526 4.07704 12.6143L7.36931 13.6355L14.8386 8.96186C18.9467 6.39135 22.3916 4.25925 22.494 4.22381C22.752 4.13465 22.9685 4.14342 23.0724 4.24725C23.1529 4.32781 23.1522 4.34775 23.0626 4.48888C23.009 4.57349 20.2788 7.05167 16.9955 9.99596C13.7123 12.9402 11.0178 15.3698 11.0078 15.3951C10.9978 15.4203 10.8861 16.8977 10.7596 18.6782L10.5295 21.9153L10.7777 21.8819C10.9143 21.8634 11.1304 21.793 11.2579 21.7254C11.3855 21.6578 12.2442 20.88 13.1662 19.9969C14.0881 19.1138 14.8752 18.3787 14.9152 18.3634C14.9552 18.3481 16.4254 19.389 18.1824 20.6765C19.9393 21.9641 21.5124 23.0802 21.678 23.1568C22.1143 23.3585 22.6127 23.3781 22.9339 23.2062C23.2218 23.0521 23.5038 22.669 23.6219 22.2712C23.6675 22.1174 24.6711 17.4803 25.852 11.9665C27.8174 2.7902 27.9993 1.8974 28 1.42115C28.0007 0.970917 27.9821 0.864013 27.8614 0.62704C27.7764 0.460003 27.6313 0.28946 27.4896 0.189786C27.1656 -0.0381514 26.6291 -0.0627414 26.1141 0.126695Z"></path>
                </svg>
              </a>
            </li>
            <li class="item">
              <a
                href="https://discord.gg/ymdx9694"
                target="_blank"
                rel="noreferrer noopener"
                class="link"
              >
                <svg
                  class="icon"
                  width="26"
                  height="20"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M17.1623 1.26368C15.8876 0.678776 14.5206 0.247847 13.0914 0.00103534C13.0653 -0.00372804 13.0393 0.00817591 13.0259 0.0319844C12.8501 0.344664 12.6554 0.752579 12.519 1.0732C10.9818 0.843058 9.45243 0.843058 7.94671 1.0732C7.81032 0.745452 7.60852 0.344664 7.43192 0.0319844C7.41852 0.00897027 7.39252 -0.00293367 7.36649 0.00103534C5.93804 0.247058 4.57108 0.677988 3.29556 1.26368C3.28452 1.26844 3.27505 1.27638 3.26877 1.28669C0.675938 5.16033 -0.0343466 8.93875 0.314095 12.6703C0.315672 12.6886 0.32592 12.706 0.34011 12.7171C2.05079 13.9734 3.70787 14.7361 5.33418 15.2416C5.36021 15.2496 5.38778 15.24 5.40435 15.2186C5.78905 14.6933 6.13198 14.1393 6.42601 13.5568C6.44336 13.5227 6.4268 13.4822 6.39133 13.4687C5.84739 13.2624 5.32945 13.0108 4.83122 12.7251C4.79181 12.7021 4.78866 12.6457 4.82491 12.6187C4.92976 12.5402 5.03463 12.4584 5.13474 12.3759C5.15285 12.3608 5.17809 12.3576 5.19939 12.3671C8.47252 13.8615 12.0161 13.8615 15.2506 12.3671C15.2719 12.3568 15.2971 12.36 15.316 12.3751C15.4162 12.4576 15.521 12.5402 15.6266 12.6187C15.6629 12.6457 15.6605 12.7021 15.6211 12.7251C15.1229 13.0163 14.6049 13.2624 14.0602 13.4679C14.0247 13.4814 14.009 13.5227 14.0263 13.5568C14.3267 14.1385 14.6696 14.6924 15.0472 15.2178C15.063 15.24 15.0913 15.2496 15.1174 15.2416C16.7516 14.7361 18.4086 13.9734 20.1193 12.7171C20.1343 12.706 20.1438 12.6894 20.1453 12.6711C20.5623 8.357 19.4469 4.60957 17.1883 1.28748C17.1828 1.27638 17.1733 1.26844 17.1623 1.26368ZM6.9148 10.3982C5.92936 10.3982 5.11739 9.49348 5.11739 8.38241C5.11739 7.27134 5.91361 6.36664 6.9148 6.36664C7.92384 6.36664 8.72795 7.27928 8.71218 8.38241C8.71218 9.49348 7.91595 10.3982 6.9148 10.3982ZM13.5604 10.3982C12.575 10.3982 11.763 9.49348 11.763 8.38241C11.763 7.27134 12.5592 6.36664 13.5604 6.36664C14.5695 6.36664 15.3736 7.27928 15.3578 8.38241C15.3578 9.49348 14.5695 10.3982 13.5604 10.3982Z"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
