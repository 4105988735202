import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container"></div>
        </div>
      </div>
    </header>
  );
};
