import React from "react";

const CustomButton = ({ text, href, variant }) => {
  return (
    <a target="blank" href={href} className={`btn custom-btn ${variant}`}>
      {text}
    </a>
  );
};

export default CustomButton;
