import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="gallery" className="carousel_container">
      <div className="container">
        <div className="section-title">
          <h2>Nocha's Gallery</h2>
        </div>

        <div className="carousel">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="carousel-item">
                  <Image
                    title={d.title}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                  />
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
