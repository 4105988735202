import React from "react";
import CustomButton from "./button";

const Story = ({ text, href }) => {
  return (
    <div id="story" className="story_container">
      <div className="container">
        <div className="story_img">
          <img src="./img/nocha_story.png" alt="Nocha" className="logo-img" />
        </div>
        <div className="section-title">
          <h2>Nocha story</h2>
          <p>
            In an Italian village, under the light of a full moon, Nacho, a
            black cat with green eyes, wandered through an alley he had never
            explored before. Suddenly, he saw Nocha, an elegant female cat with
            long white fur, perched on an old well. Her green eyes immediately
            caught his attention, and his heart began to flutter. Fascinated,
            Nacho approached awkwardly. Nocha looked at him with a mixture of
            curiosity and amusement. Trying to impress her, he circled around
            her, but his overflowing enthusiasm betrayed his feelings.
            Playfully, Nocha trotted away, inviting Nacho to follow her through
            the quiet alleys. They spent the night chasing each other and
            jumping from roof to roof under the watchful gaze of the stars. As
            dawn approached, Nocha stopped, moved closer to Nacho, and gently
            rubbed her head against his. It was the beginning of an eternal
            love, a bond formed under the moon in a small Italian village.
          </p>
        </div>
        <div className="buttons">
          <CustomButton
            text="KatScan"
            href="https://katscan.nachowyborski.xyz/tokens/NOCHA"
            variant="btn-secondary"
          />

          <CustomButton
            text="Buy now"
            href="https://kas.fyi/token/krc20/NOCHA"
            variant="btn-secondary"
          />

          <CustomButton
            text="Whitepaper soon"
            href="https://kas.fyi/token/krc20/NOCHA"
            variant="btn-secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default Story;
