import React, { useState } from "react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What are KRC-20 tokens?",
      answer:
        "KRC-20 tokens are built on the Kaspa Network using the KasPlex protocol.",
    },
    {
      question: "How do you buy $NOCHA tokens?",
      answer:
        "Some popular KRC-20 tokens can be bought on centralized exchanges, while others – through the marketplaces.",
    },
    {
      question: "How are KRC-20 tokens created?",
      answer:
        "Kasplex employs the Pay-to-Script-Hash (P2SH) method to embed data into the Kaspa network, ensuring efficient data storage and avoiding UTXO bloat, thereby maintaining the network's scalability and longevity.",
    },
    {
      question: "What’s the difference between KRC-20 and other tokens?",
      answer:
        "The ERC-20 tokens are built on the Ethereum network and follow the ERC-20 token standard deployed using smart contracts. On the other hand, KRC-20 tokens are built on the Kaspa blockDAG and follow the KRC-20 token standard without the need for smart contracts.",
    },
    // {
    //   question: "When $NOCHA as been created ?",
    //   answer:
    //     "The ERC-20 tokens are built on the Ethereum network and follow the ERC-20 token standard deployed using smart contracts. On the other hand, KRC-20 tokens are built on the Kaspa blockDAG and follow the KRC-20 token standard without the need for smart contracts.",
    // },
  ];

  return (
    <div id="faq" className="faq_container mt-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 9.229c.234-1.12 1.547-6.229 5.382-6.229 2.22 0 4.618 1.551 4.618 5.003 0 3.907-3.627 8.47-10 12.629-6.373-4.159-10-8.722-10-12.629 0-3.484 2.369-5.005 4.577-5.005 3.923 0 5.145 5.126 5.423 6.231zm-12-1.226c0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-7.962-9.648-9.028-12-3.737-2.338-5.262-12-4.27-12 3.737z" />
      </svg>

      <div className="container faq">
        <div className="section-title">
          <h2>Faq</h2>
        </div>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <span className="faq-toggle">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              {activeIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
