import React from "react";
import CustomButton from "./button";

export const Navigation = (props) => {
  return (
    <div className="bg-header bg-custom-bg bg-cover bg-center bg-no-repeat relative h-screen">
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <svg
                width="40px"
                height="40px"
                viewBox="0 0 256 256"
                id="Flat"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M189.02051,145.33984A31.35052,31.35052,0,0,1,174.0918,126.606a47.99847,47.99847,0,0,0-92.18262-.00635,31.35,31.35,0,0,1-14.92969,18.74023,44.00739,44.00739,0,0,0,38.11719,79.21094,60.16331,60.16331,0,0,1,45.80664,0,44.00678,44.00678,0,0,0,38.11719-79.21094ZM168,204a19.86485,19.86485,0,0,1-7.80078-1.57568c-.04395-.019-.08887-.0376-.13379-.05616a84.02637,84.02637,0,0,0-64.13086,0c-.04492.01856-.08984.03711-.13379.05616a20.00673,20.00673,0,0,1-17.31445-36.02246c.03515-.01954.07129-.03907.10644-.05909A55.21137,55.21137,0,0,0,104.957,133.29541a23.99908,23.99908,0,0,1,46.08887.00439,55.20367,55.20367,0,0,0,26.36133,33.043c.03515.02.07129.03955.10644.05909A20.00364,20.00364,0,0,1,168,204Zm64-100a24,24,0,1,1-24-24A23.99994,23.99994,0,0,1,232,104ZM48,128a24,24,0,1,1,24-24A23.99994,23.99994,0,0,1,48,128ZM72,56A24,24,0,1,1,96,80,23.99994,23.99994,0,0,1,72,56Zm64,0a24,24,0,1,1,24,24A23.99994,23.99994,0,0,1,136,56Z" />
              </svg>
            </button>
            <a className="page-scroll" href="#page-top">
              <img
                src="./img/logo.png"
                alt="Logo of Nocha"
                className="logo-img"
              />
              <span>Nocha the 𐤊at</span>
            </a>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav">
              <li>
                <a href="#story" className="page-scroll">
                  Story
                </a>
              </li>
              <li>
                <a href="#tokenomic" className="page-scroll">
                  Tokenomic
                </a>
              </li>
              <li>
                <a href="#gallery" className="page-scroll">
                  Nocha's Gallery
                </a>
              </li>
              <li>
                <a href="#faq" className="page-scroll">
                  Faq
                </a>
              </li>
              <li>
                <a href="#socials" className="page-scroll">
                  Socials
                </a>
              </li>
            </ul>
            <CustomButton
              text="Buy now"
              href="https://kas.fyi/token/krc20/NOCHA"
              variant="btn-primary"
            />
          </div>
        </div>
      </nav>
    </div>
  );
};
