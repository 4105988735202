import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Gallery } from "./components/gallery";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Tokenomics from "./components/tokenomics";
import Story from "./components/story";
import Footer from "./components/footer";
import FAQ from "./components/faq";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: function (anchor, toggle) {
    const headerHeight = document.querySelector(".navbar").offsetHeight;
    return headerHeight;
  },
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <img
        src="./img/title_bg.png"
        alt="Logo of Nocha"
        className="bg-title-header"
      />
      <Navigation />
      <Header data={landingPageData.Header} />
      <Story />
      <Tokenomics />
      <Gallery data={landingPageData.Gallery} />
      <FAQ />
      <Footer />
    </div>
  );
};

export default App;
